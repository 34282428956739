import React from 'react';
import PropTypes from 'prop-types';
import './ShowcaseSection.scss';

const ShowcaseSection = ({children, page, bgImage}) => {
  return (
    <section
      className={`showcase-container ${page}-showcase`}
      style={{backgroundImage: `url(${bgImage})`}}
    >
      <div className="showcase-section-1">{children}</div>
      <div className="showcase-section-2">
        <div className="division" />
      </div>
    </section>
  );
};

ShowcaseSection.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired
};

export default ShowcaseSection;
