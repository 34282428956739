import React from 'react';
import TeamMember from 'components/shared/TeamMember/TeamMember';
import GunnarSteinicke from 'assets/images/team-members/gunnar-steinicke.png';
import DirkSchwendt from 'assets/images/team-members/dirk-schwendt.png';
import TinaManderscheid from 'assets/images/team-members/tina-manderscheid.png';
import SylviaBerge from 'assets/images/team-members/sylvia-berge.png';
import './TeamMembers.scss';

export default function TeamMembers() {
  const Members = [
    {
      id: 0,
      firstname: 'Dirk',
      lastname: 'Schwendt',
      role: 'Node Lead & Go-To-Market',
      role2: 'Lead',
      contact: 'dirk.schwendt@accenture.com',
      profilePicture: DirkSchwendt,
      gender: 'male'
    },
    {
      id: 1,
      firstname: 'Gunnar',
      lastname: 'Steinicke',
      role: 'Learning Technology',
      role2: 'Lead',
      contact: 'gunnar.steinicke@accenture.com',
      profilePicture: GunnarSteinicke,
      gender: 'male'
    },

    {
      id: 2,
      firstname: 'Tina',
      lastname: 'Manderscheid',
      role: 'Change Communication',
      role2: '& Media Design Lead',
      contact: 'tina.manderscheid@accenture.com',
      profilePicture: TinaManderscheid,
      gender: 'female'
    },
    {
      id: 3,
      firstname: 'Sylvia',
      lastname: 'Berge',
      role: 'Cyber Security',
      role2: 'Lead',
      contact: 'sylvia.berge@accenture.com',
      profilePicture: SylviaBerge,
      gender: 'female'
    }
  ];
  return (
    <section className="team-members-container" id="the-team">
      {Members.map(member => (
        <TeamMember
          key={member.id}
          id={member.id}
          firstname={member.firstname}
          lastname={member.lastname}
          role={member.role}
          role2={member.role2}
          contact={member.contact}
          profilePicture={member.profilePicture}
          gender={member.gender}
        />
      ))}
    </section>
  );
}
