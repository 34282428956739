import React, {useState} from 'react';
import DropDownButton from 'components/shared/TabContainer/DropDownButton';
import './Multimedia-Tabcontainer.scss';
import {Nav} from 'react-bootstrap';
import MediaAwareness from 'assets/documents/multimedia/AwarenessCampaignBundle.pdf';
import MediaGoLive from 'assets/documents/multimedia/GoLive_Campaigns.pdf';
import MediaProjectBundle from 'assets/documents/multimedia/Project_StartBundle.pdf';
import MediaTrainingDesign from 'assets/documents/multimedia/Training_Design_Bundle.pdf';

export default function MultimediaTabcontainer() {
  const [tab, setTab] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  /* eslint-disable */
  const onClickHandler = activeTab => {
    if (!tab) {
      setTab(true);
    }
    setActiveTab(activeTab);
    // setActivePanel(activePanel);
    // if (tab) {
    //   setTab(false);
    // }
  };

  return (
    <div className="multi-media">
      <div className="toggle-container">
        <button
          type="button"
          className={tab && activeTab === 1 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(1)}
        >
          {/* {tab && activeTab === 1 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 2 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(2)}
        >
          {/* {tab && activeTab === 2 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 3 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(3)}
        >
          {/* {tab && activeTab === 3 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
      </div>
      {tab && activeTab === 1 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header1">
                <h3>Use a visual branding as your transformation agent</h3>
              </div>
              <div className="tabcontent-col " />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  “People will forget what you said, people will forget what you did, but people
                  will never forget how you made them feel.” Maya Angelou Every communication
                  measure is an ambassador of your project to your target groups. With an excellent
                  design concept – inlcuding visuals, color schemes and harmonized layouts – you
                  determine how people will think and feel about your program. We help you to build
                  a visual brand that will serve as your agent in your transformation process.
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={MediaProjectBundle} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 2 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header2">
                <h3>Enable learning attention and go-live buy-in with visual branding </h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  It is a prerequisite for the effectiveness of learning formats and go-live
                  measures to build on a powerful project visual design throughout. It is only then
                  that the full attention in learning as well as of go-live activities aimed at
                  making the way for go-live success will be released in full. Our training design
                  bundle and go-live campaigns are the way makers!  
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <DropDownButton
                  linkText1="Training design bundle"
                  linkText2="Go-live campaigns"
                  link1={MediaTrainingDesign}
                  link2={MediaGoLive}
                />
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 3 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header3">
                <h3>Grab the eye and mind of all your target groups  </h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  Successful roll-out of a project, program or campaign – whether rolled out within
                  or without your organization - requires first of all the awareness of all target
                  groups at stake. This is an interactive process of attracting attention of your
                  personnel and/or clients and making them respond with not only processing your
                  information but ideally wanting to learn more about what it is all about. Master
                  their awareness with our awareness campaign design!
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={MediaAwareness} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
