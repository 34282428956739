import React from 'react';
import './LearningTechnology-Challenges.scss';
import Challenge from 'components/shared/Challenge/Challenge';
import CornerStone from 'assets/icons/cornerStoneIcon.png';
import PuzzleIcon from 'assets/icons/puzzleIcon.png';
import StrategyIcon from 'assets/icons/strategyIcon.png';

export default function LearningTechnologyChallenges() {
  return (
    <div className="challenges below-hero">
      <h4>LEARNING TECHNOLOGY</h4>
      <h2>Challenges</h2>
      <p className="challenge-introduction">
        With workplace learning trends emerging and technologies transforming the world of work,
        businesses need to rethink their approach towards L&D and employee training. Today’s
        employees expect high technological engagement in every part of life, including skill
        development, personalized learning journeys and learning in the flow of work. Learning
        Technology remains a top enabler for organizations seeking to align L&D with business
        objectives and is a key factor to achieve improved learning engagement and higher
        productivity in the long run.
      </p>
      <section className="challenges-topic-container">
        <Challenge
          id={2}
          sectionName="challenges-topic"
          title="Learning Technology Ecosystem Strategy -Co-Creating a North Star"
          description="When it comes to learning in an organization, providing a state-of-the-art employee experience has never been more important. But how do we know which learning technology solution is the right one to achieve this?"
          challengesIcon={StrategyIcon}
        />
        <Challenge
          id={3}
          sectionName="challenges-topic"
          title="Implementing your new Learning or Knowledge Management Solution"
          description="How can we create a successful end-to-end transformation journey once the right tools for the learning technology ecosystem or knowledge management solution are found?"
          challengesIcon={PuzzleIcon}
        />
        <Challenge
          id={4}
          sectionName="challenges-topic"
          title="Cornerstone OnDemand? We are certified partner!"
          description="With tools as highly configurable as the Cornerstone Talent Suite, how can be make sure to get the most out of the Cornerstone solutions for every organization?"
          challengesIcon={CornerStone}
        />
      </section>
    </div>
  );
}
