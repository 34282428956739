import React, {useState, useEffect} from 'react';
import './ChangeCom-Tabcontainer.scss';
import {Nav} from 'react-bootstrap';
import CoCreation from 'assets/documents/comms/GLACE_Change_CoCreation.pdf';
import CommFormats from 'assets/documents/comms/GLACE_Change_Formats.pdf';
import CommsValue from 'assets/documents/comms/GLACE_Change_ValueChain.pdf';
import CommsCyberSec from 'assets/documents/comms/GLACE_Change_CyberSec.pdf';

export default function ChangeComTabcontainer() {
  const [tab, setTab] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  /* eslint-disable */
  const onClickHandler = activeTab => {
    if (!tab) {
      setTab(true);
    }
    setActiveTab(activeTab);
    // setActivePanel(activePanel);
    // if (tab) {
    //   setTab(false);
    // }
  };

  useEffect(() => {
    if (window.location.hash === '#secure-behaviour') {
      console.log(window.location.hash);
      setTab(true);
      setActiveTab(3);
    }
  }, [window.location.hash]);

  return (
    <div className="ChangeCom">
      <div className="toggle-container">
        <button
          type="button"
          className={tab && activeTab === 1 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(1)}
        >
          {/* {tab && activeTab === 1 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 2 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(2)}
        >
          {/* {tab && activeTab === 2 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 3 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(3)}
        >
          {/* {tab && activeTab === 3 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
      </div>
      {tab && activeTab === 1 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header1">
                <h3>Develop engaging communication formats to drive employee readiness</h3>
              </div>
              <div className="tabcontent-col header1">
                <h3>Support business transformation by end-to-end change communication measures</h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  The success of a system implementation largely depends on employees’ change
                  readiness. To support this, we create appealing communication formats that explain
                  the new system’s benefits and make employees eager to use it. 
                </p>
              </div>
              <div className="tabcontent-col">
                <p>
                  Major transformations require multilayered communications that take all
                  stakeholders into account. We deliver end-to-end communication measures from
                  stakeholder identification and multichannel distribution of key messages to
                  employee engagement, ensuring the organization understands and adopts the change. 
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={CommFormats} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    View Format Overview
                  </button>
                </a>
              </div>
              <div className="tabcontent-col">
                <a href={CommsValue} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 2 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header2">
                <h3>
                  Helping our clients go about things differently with Design Thinking and
                  Co-creation
                </h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  If clear visions of the future are missing, change projects threaten to fail. We
                  co-create the change vision together with our clients, using Design Thinking and
                  creative ideation methods to help understand and articulate challenges, identify
                  opportunities and the desired future state and prioritize ideas based on value.  
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={CoCreation} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 3 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header3">
                <h3>Implement a cyber security awareness journey </h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  To support our clients minimizing security risks and staying safe, our team is
                  offering an off-the-shelve cyber security awareness journey. Clients can either
                  implement the whole journey consisting of different communication and training
                  modules or can just choose single modules that best suit their security needs. 
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={CommsCyberSec} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
