import React from 'react';
import './Footer.scss';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const year = new Date().getFullYear();

  return (
    <section className="footer-container">
      <div className="footer-links">
        <button id="back-to-top" type="button" onClick={scrollToTop}>
          Back to top
        </button>
        <a
          href="https://www.accenture.com/ca-en/support/company-cookies-similar-technology"
          target="_blank"
          rel="noopener noreferrer">
          <button type="button">Cookie Policy</button>
        </a>
        <a
          href="https://www.accenture.com/ca-en/about/privacy-policy"
          target="_blank"
          rel="noopener noreferrer">
          <button type="button">Privacy statement</button>
        </a>
        <a
          href="https://www.accenture.com/ca-en/support/terms-of-use"
          target="_blank"
          rel="noopener noreferrer">
          <button type="button">Terms & Conditions</button>
        </a>
      </div>
      <div className="footer-copyright">
        <span>© Copyright {year} Accenture</span>
      </div>
    </section>
  );
};

export default Footer;
