import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FooterTopicCarousel.scss';
// import PropTypes from 'prop-types';
import ArrowPrev from 'assets/icons/arrow-prev.svg';
import ArrowNext from 'assets/icons/arrow-next.svg';

const ArrowLeft = props => (
  <button
    {...props}
    type="button"
    className="footer-topic-carousel-arrow footer-topic-carousel-prev-btn"
  >
    <img src={ArrowPrev} alt="Previous" />
  </button>
);
const ArrowRight = props => (
  <button
    {...props}
    type="button"
    className="footer-topic-carousel-arrow footer-topic-carousel-next-btn"
  >
    <img src={ArrowNext} alt="Next" />
  </button>
);
const FooterTopicCarousel = ({children, page, infinite}) => {
  const settings = {
    dots: true,
    infinite,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings} className={`footer-topic-carousel ${page}-footer-topic-carousel`}>
      {children}
    </Slider>
  );
};

// FooterTopicCarousel.defaultProps = {
//   infinite: true
// };

// FooterTopicCarousel.propTypes = {
//   children: PropTypes.node.isRequired,
//   page: PropTypes.string.isRequired,
//   infinite: PropTypes.bool
// };

export default FooterTopicCarousel;
