import React from 'react';
import {Helmet} from 'react-helmet';
import LearningTechnologyChallenges from './components/LearningTechnology-Challenges';
import LearningTechnologyTabcontainer from './components/LearningTechnology-Tabcontainer';
import LearningTechShowcase from './components/LearningTechnology-Showcase';
import LearningTechHero from './components/LearningTechnology-Hero';
import LearningTechFooterTopic from './components/LearningTechnology-Footer-Topic';

const LearningTechPage = () => {
  return (
    <>
      <Helmet>
        <title>GLACE | Accenture | Learning Technology</title>
      </Helmet>
      <LearningTechHero />
      <LearningTechnologyChallenges />
      <LearningTechnologyTabcontainer />
      <LearningTechShowcase />
      <LearningTechFooterTopic />
    </>
  );
};

export default LearningTechPage;
