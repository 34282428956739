import React from 'react';
import './Button.scss';
// import Proptypes from 'prop-types';

const Button = ({children, theme, type = 'button'}) => {
  return (
    <button type={type} className={`button button-${theme}`}>
      {children}
    </button>
  );
};
// Button.propTypes = {
//   children: Proptypes.node,
//   theme: Proptypes.string,
//   type: Proptypes.string
// };

// Button.defaultProps = {
//   children: 'Learn More',
//   theme: '',
//   type: 'button'
// };

export default Button;
