import React from 'react';
import Proptypes from 'prop-types';
import './HeroBanner.scss';
import {Link} from 'react-router-dom';
import AccentureLogo from 'assets/images/accenture-logo.png';
import DownArrowIcon from 'assets/icons/down-arrow.svg';

const HeroBanner = ({taglineTop, title, taglineBottom, description, rightContentSlot, page}) => {
  const handleScrollDown = () => {
    const element = document.getElementsByClassName('below-hero');
    if (element[0] !== undefined) {
      element[0].scrollIntoView({behavior: 'smooth'});
    }
  };
  return (
    <>
      <section className={`hero hero-container hero-${page}`}>
        <div className="hero-left">
          <Link to="/">
            <img className="hero-accenture-logo" src={AccentureLogo} alt="Accenture logo" />
          </Link>
          {taglineTop !== '' && <p className="hero-tagline-top">{taglineTop}</p>}
          {title !== '' && <h1 className="hero-page-title">{title}</h1>}
          {taglineBottom !== '' && <p className="hero-tagline-bottom">{taglineBottom}</p>}
          {description !== '' && <div className="hero-description">{description}</div>}
        </div>
        <div className="hero-right">{rightContentSlot}</div>
      </section>
      <div
        className="hero-arrow-down"
        role="button"
        tabIndex="0"
        onClick={handleScrollDown}
        onKeyPress={handleScrollDown}
      >
        <img src={DownArrowIcon} alt="Down Arrow" />
      </div>
    </>
  );
};

HeroBanner.propTypes = {
  page: Proptypes.string.isRequired,
  taglineTop: Proptypes.string,
  title: Proptypes.string.isRequired,
  taglineBottom: Proptypes.string,
  description: Proptypes.node,
  rightContentSlot: Proptypes.node
};

HeroBanner.defaultProps = {
  taglineTop: 'Our Offering',
  taglineBottom: 'GLOBAL LEARNING & COMMUNICATION EXPERTS',
  description: '',
  rightContentSlot: ''
};

export default HeroBanner;
