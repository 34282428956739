import React from 'react';
import ShowcaseSection from 'components/shared/ShowcaseSection/ShowcaseSection';
import bgImage from 'assets/images/learn-tech-bg.png';
import {useTranslation, Trans} from 'react-i18next';

const LearningTechnologyShowcase = () => {
  const {t} = useTranslation();

  const page = 'learning-technology';
  const section = 'showcase-section';

  const tpath = `${page}:${section}`;

  return (
    <ShowcaseSection page={page} bgImage={bgImage}>
      <h3>
        <Trans t={t} i18nKey={`${tpath}:title`} />
      </h3>
      <p>
        <Trans t={t} i18nKey={`${tpath}:description`} />
      </p>
    </ShowcaseSection>
  );
};

export default LearningTechnologyShowcase;
