import React from 'react';
import {useTranslation} from 'react-i18next';
import HeroBanner from 'components/shared/HeroBanner/HeroBanner';
import './ChangeCom-Hero.scss';

const ChangeComHero = () => {
  const {t} = useTranslation();

  const page = 'change-communication';
  const hero = `${page}:hero`;

  const taglineTop = t('our-offering');
  const title = t(`${hero}:title`);
  const taglineBottom = t('glace');

  const description = (
    <p>
      We support our clients along the complete project value chain by providing tailored change
      communication solutions.
    </p>
  );

  return (
    <HeroBanner
      page={page}
      taglineTop={taglineTop}
      title={title}
      description={description}
      taglineBottom={taglineBottom}
    />
  );
};

export default ChangeComHero;
