import React from 'react';
import './LearningDesign-Challenges.scss';
import Challenge from 'components/shared/Challenge/Challenge';
import LearningIcon from 'assets/icons/learningIcon.png';
import CloudIcon from 'assets/icons/cloudIcon.png';

export default function LearningDesignChallenges() {
  return (
    <div className="challenges-learnind-design below-hero">
      <h4>
        LEARNING
        <br />
        DESIGN & DEVELOPMENT
      </h4>
      <h2>Challenges</h2>
      <section className="challenges-topic-container">
        <div className="challenges challenges-topic-0">
          <p>
            At our clients we see several needs of learning solutions. With our team we bring
            experience in supporting training for all kinds of cloud solutions. We are able to scale
            our training support to your needs from small and local to very large global rollouts
            across countries and languages by providing attractive customized learning formats.{' '}
          </p>
        </div>
        <Challenge
          id={1}
          challengeId="cloud-solutions"
          sectionName="challenges-topic"
          title="Train employees for Cloud-Solutions"
          description="How can we make sure, that employees really know and understand new systems and processes like S/4HANA, SuccessFactors, Workday, Salesforce, Ariba, Coupa?"
          challengesIcon={CloudIcon}
        />
        <Challenge
          id={2}
          challengeId="customized-learning"
          sectionName="challenges-topic"
          title="Provide appealing customized learning formats"
          description="How can we use digital learning formats to upskill our employees on process-, functional- system- or compliance topics like Cyber Security?"
          challengesIcon={LearningIcon}
        />
      </section>
    </div>
  );
}
