import React from 'react';
import {useTranslation} from 'react-i18next';
import HeroBanner from 'components/shared/HeroBanner/HeroBanner';
import './Multimedia-Hero.scss';

const MultimediaHero = () => {
  const {t} = useTranslation();

  const page = 'multimedia-design';
  const hero = `${page}:hero`;

  const taglineTop = t('our-offering');
  const title = t(`${hero}:title`);
  const taglineBottom = t('glace');

  const description = (
    <p>
      If applied in learning, communication or another subject area the design of your multimedia is
      THE key to attract your audience and get your message across as effectively as possible. Let
      us introduce you to our broad spectrum of design services that will boost your multimedia with
      the wow factor to achieve this inevitable goal of attractiveness and effectiveness. 
    </p>
  );

  return (
    <HeroBanner
      page={page}
      taglineTop={taglineTop}
      title={title}
      description={description}
      taglineBottom={taglineBottom}
    />
  );
};

export default MultimediaHero;
