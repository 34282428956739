import React from 'react';
import './Podcast.scss';
import Button from 'components/ui/Button/Button';

export default function Podcast() {
  return (
    <section className="podcast-container">
      <div className="podcast podcast-left">
        <h4>
          Listen to our
          <br />
          Learning Podcast
        </h4>
        <p className="playing">„Die Lernkurve“</p>
        <p className="description">
          In this German podcast Dirk Schwendt discusses several topics around digital learning and
          communication with internal and external guests.
        </p>
      </div>
      <div className="podcast podcast-right">
        <a
          href="https://open.spotify.com/show/5KCjSFjhMe2te7zDbO1TLO"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="podcast-btn" theme="spotify">
            Follow on Spotify
          </Button>
        </a>
        <a
          href="https://podcasts.apple.com/us/podcast/die-lernkurve/id1489524309"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="podcast-btn" theme="apple">
            Follow on Apple Podcasts
          </Button>
        </a>
      </div>
    </section>
  );
}
