import React from 'react';
import {Helmet} from 'react-helmet';
import MultimediaHero from './components/Multimedia-Hero';
import MultimediaChallenges from './components/Multimedia-Challenges';
import MultimediaTabcontainer from './components/Multimedia-Tabcontainer';

const MultiMediaDesignPage = () => {
  return (
    <>
      <Helmet>
        <title>GLACE | Accenture | Multimedia Design</title>
      </Helmet>
      <MultimediaHero />
      <MultimediaChallenges />
      <MultimediaTabcontainer />
    </>
  );
};

export default MultiMediaDesignPage;
