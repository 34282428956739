import React from 'react';
import FooterTopic from 'components/shared/Topic/FooterTopic';
import FooterTopicCarousel from 'components/shared/FooterTopicCarousel/FooterTopicCarousel';
import LearningTechnology from 'assets/documents/learn-tech/Showcase_Northstar.pdf';
import SeamlessShift from 'assets/documents/learn-tech/Showcase_LMS.pdf';
import ChangeManagement from 'assets/documents/learn-tech/Showcase_ChangeManagement.pdf';
import LifeLong from 'assets/documents/learn-tech/Showcase_Lifelong_Learning.pdf';
import CSODExpertise from 'assets/documents/learn-tech/Showcase_CSODExpertise.pdf';

import {useTranslation} from 'react-i18next';
import './LearningTechnology-Footer-Topic.scss';

const LearningTechFooterTopic = () => {
  const {t} = useTranslation();

  const page = 'learning-technology';
  const section = 'footer-topic';

  const sectionName = `${page}-${section}`;
  const tpath = `${page}:${section}`;

  return (
    <section className={`${sectionName}-container`}>
      <FooterTopicCarousel page={page}>
        <FooterTopic
          id={0}
          sectionName={sectionName}
          title={t(`${tpath}.0.title`)}
          description={t(`${tpath}.0.description`)}
          buttonUrl={LearningTechnology}
        />
        <FooterTopic
          id={1}
          sectionName={sectionName}
          title={t(`${tpath}.1.title`)}
          description={t(`${tpath}.1.description`)}
          buttonUrl={SeamlessShift}
        />
        <FooterTopic
          id={2}
          sectionName={sectionName}
          title={t(`${tpath}.2.title`)}
          description={t(`${tpath}.2.description`)}
          buttonUrl={ChangeManagement}
        />
        <FooterTopic
          id={3}
          sectionName={sectionName}
          title={t(`${tpath}.3.title`)}
          description={t(`${tpath}.3.description`)}
          buttonUrl={LifeLong}
        />
        <FooterTopic
          id={4}
          sectionName={sectionName}
          title={t(`${tpath}.4.title`)}
          description={t(`${tpath}.4.description`)}
          buttonUrl={CSODExpertise}
        />
      </FooterTopicCarousel>
    </section>
  );
};

export default LearningTechFooterTopic;
