import React from 'react';
import {useTranslation} from 'react-i18next';
import HeroBanner from 'components/shared/HeroBanner/HeroBanner';
import './LearningDesign-Hero.scss';

const LearningDesignHero = () => {
  const {t} = useTranslation();

  const page = 'learning-design';
  const hero = `${page}:hero`;

  const taglineTop = t('our-offering');
  const title = t(`${hero}:title`);
  const taglineBottom = t('glace');

  const description = (
    <p>
      We serve our clients on all aspects of the learning value chain by providing innovative and
      efficient support.
    </p>
  );

  return (
    <HeroBanner
      page={page}
      taglineTop={taglineTop}
      title={title}
      description={description}
      taglineBottom={taglineBottom}
    />
  );
};

export default LearningDesignHero;
