import React from 'react';
import FooterTopic from 'components/shared/Topic/FooterTopic';
import FooterTopicCarousel from 'components/shared/FooterTopicCarousel/FooterTopicCarousel';
import ShowcaseIT from 'assets/documents/comms/GLACE_Showcase_ITTransformation_OC.pdf';
import {useTranslation} from 'react-i18next';
import './ChangeCom-Footer-Topic.scss';

const ChangeComFooterTopic = () => {
  const {t} = useTranslation();

  const page = 'change-communication';
  const section = 'footer-topic';

  const sectionName = `${page}-${section}`;
  const tpath = `${page}:${section}`;

  return (
    <section className={`${sectionName}-container`}>
      <FooterTopicCarousel page={page}>
        <FooterTopic
          id={0}
          sectionName={sectionName}
          title={t(`${tpath}.0.title`)}
          description={t(`${tpath}.0.description`)}
          buttonUrl={ShowcaseIT}
        />
        <FooterTopic
          id={1}
          sectionName={sectionName}
          title={t(`${tpath}.1.title`)}
          description={t(`${tpath}.1.description`)}
          hide="hide"
        />
        <FooterTopic
          id={0}
          sectionName={sectionName}
          title={t(`${tpath}.2.title`)}
          description={t(`${tpath}.2.description`)}
          hide="hide"
        />
      </FooterTopicCarousel>
    </section>
  );
};

export default ChangeComFooterTopic;
