import React from 'react';
import Button from 'components/ui/Button/Button';
import './Offering.scss';
import {Link} from 'react-router-dom';
import OfferingBlue from 'assets/images/offering-blue.png';
import OfferingGreen from 'assets/images/offering-green.png';
import OfferingOrange from 'assets/images/offering-orange.png';
import OfferingYellow from 'assets/images/offering-yellow.png';

export default function Offering() {
  return (
    <section className="offering-container">
      <div className="offering offering-text">
        <span className="section-name">
          OUR
          <br />
          Offering
        </span>
        <h2>Learning Communication & Design</h2>
        <p>
          In every transformation program employees are affected one way or another by the changes
          the program brings.
        </p>
        <p>
          So communicating with those affected and enabling them to be productive in the transformed
          setting is key to success of every transformation program.
        </p>
        <p>
          We bring ideas, experience and people for all related aspects like Learning Design &
          Development, Learning Technology, designing the appropriate change communication and
          wrapping deliverables in a very appealing design.
        </p>
      </div>
      <div className="offering offering-blue">
        <img className="offering-image" src={OfferingBlue} alt="Learning Design and Delivery" />
        <h3>
          Learning
          <br />
          Design & Development
        </h3>
        <div className="hidden-content">
          <p>
            Based on your situation, needs and preferences we design compelling learning formats and
            develop learning content in close alignment with you.
          </p>
          <Link to="/design">
            <Button theme="white" />
          </Link>
        </div>
      </div>
      <div className="offering offering-green">
        <img className="offering-image" src={OfferingGreen} alt="Learning Design and Development" />
        <h3>Learning Technology</h3>
        <div className="hidden-content">
          <p>
            We help you to make the right choices about your Learning Technology Ecosystem and
            provide the right employee experience through successful implementation.
          </p>
          <Link to="/tech">
            <Button theme="white" />
          </Link>
        </div>
      </div>
      <div className="offering offering-orange">
        <img className="offering-image" src={OfferingOrange} alt="Learning Design and Delivery" />
        <h3>Change Communication</h3>
        <div className="hidden-content">
          <p>
            We support you along the complete project value chain by providing tailored change
            communication solutions.
          </p>
          <Link to="/change-communication">
            <Button theme="white" />
          </Link>
        </div>
      </div>
      <div className="offering offering-yellow">
        <img className="offering-image" src={OfferingYellow} alt="Learning Design and Delivery" />
        <h3>
          Multimedia
          <br />
          Design
        </h3>
        <div className="hidden-content">
          <p>
            Successful multimedia design that paints a clear and inspiring message shortens the
            distance between content and audience and fosters thoughtful interaction – We support
            you to achieve a wow effect with memorable multimedia design.​
          </p>
          <Link to="/multimedia-design">
            <Button theme="white" />
          </Link>
        </div>
      </div>
    </section>
  );
}
