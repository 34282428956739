import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import Layout from 'components/Layout';
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from 'components/ScrollToTop';
import HomePage from 'containers/home-page/HomePage';
import LearningDesignPage from 'containers/learning-design/LearningDesignPage';
import LearningTechnologyPage from 'containers/learning-technology/LearningTechnologyPage';
import ChangeComPage from 'containers/change-communication/ChangeComPage';
import MultiMediaDesignPage from 'containers/multimedia-design/MultiMediaDesignPage';

function App() {
  return (
    <Suspense fallback="loading">
      <div className="App">
        <ScrollToTop />
        <Layout>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/design" component={LearningDesignPage} />
            <Route exact path="/tech" component={LearningTechnologyPage} />
            <Route exact path="/change-communication" component={ChangeComPage} />
            <Route exact path="/multimedia-design" component={MultiMediaDesignPage} />
          </Switch>
        </Layout>
      </div>
    </Suspense>
  );
}

export default App;
