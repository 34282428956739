import React from 'react';
import './Multimedia-Challenges.scss';
import Challenge from 'components/shared/Challenge/Challenge';
import AwardIcon from 'assets/icons/awardIcon.png';
import ProtectIcon from 'assets/icons/protectIcon.png';
import EyeIcon from 'assets/icons/eyeIcon.png';

export default function MultimediaChallenges() {
  return (
    <div className="challenges below-hero">
      <h4>MULTIMEDIA DESIGN</h4>
      <h2>Challenges</h2>
      <p className="challenge-introduction">
        At a time where media consumption permeates almost every aspect of our lives and reaches
        higher levels than ever, we are constantly at the verge of a sensory overload. Thus, it’s
        becoming ever more difficult to grab anyone’s attention. We have the necessary know-how and
        skills to deliver eye-catching material with a personal touch that stands out from the
        crowd.
      </p>
      <section className="challenges-topic-container">
        <Challenge
          id={2}
          sectionName="challenges-topic"
          title="Make YOUR transformation project stand out from the project crowd"
          description="Your project is important and unique – ensure that others will be convinced of its importance and uniqueness too! An excellent visual brand is a key essential to convey your project messages from the very beginning."
          challengesIcon={AwardIcon}
        />
        <Challenge
          id={2}
          sectionName="challenges-topic"
          title="Effectively establish your visual brand across all go-live preparing measures to enable go-live success"
          description="Your project’s visual brand has been finalized but now you are facing the challenge of translating these into  visually strong training and go-live campaign measures that enable profound attention for go-live success?"
          challengesIcon={EyeIcon}
        />
        <Challenge
          id={2}
          sectionName="challenges-topic"
          title="Guarantee full awareness for your roll-out activities in the sphere of information overload"
          description="Roll-out activities are thrown into today’s battlefield of ever-increasing competition for information attention – a battle that can easily be lost! Win the fight with powerful awareness campaigns as your suit of armor."
          challengesIcon={ProtectIcon}
        />
      </section>
    </div>
  );
}
