import React from 'react';
import {Helmet} from 'react-helmet';
import LearningDesignChallenges from './components/LearningDesign-Challenges';
import LearningDesignTabcontainer from './components/LearningDesign-Tabcontainer';
import LearningDesignHero from './components/LearningDesign-Hero';
import LearningDesignShowcase from './components/LearningDesign-Showcase';
import LearningDesignFooterTopic from './components/LearningDesign-Footer-Topic';

const LearningDesignPage = () => {
  return (
    <>
      <Helmet>
        <title>GLACE | Accenture | Learning Design</title>
      </Helmet>
      <LearningDesignHero />
      <LearningDesignChallenges />
      <LearningDesignTabcontainer />
      <LearningDesignShowcase />
      <LearningDesignFooterTopic />
    </>
  );
};

export default LearningDesignPage;
