import React from 'react';
import './Topics.scss';
import Topic from 'components/shared/Topic/Topic';
import {useTranslation} from 'react-i18next';

export default function Topics() {
  const {t} = useTranslation();

  return (
    <div id="home-page-trending" className="below-hero">
      <span className="section-name">{t('homepage:trending.title')}</span>
      <section className="home-topic-container">
        <Topic
          id={0}
          sectionName="home-topic"
          title={t('homepage:trending:topics.0.title')}
          description={t('homepage:trending:topics.0.description')}
          buttonText={t('homepage:trending:topics.0.buttonText')}
          buttonUrl="/design/#customized-learning"
          target="_self"
        />
        <Topic
          id={1}
          sectionName="home-topic"
          title={t('homepage:trending:topics.1.title')}
          description={t('homepage:trending:topics.1.description')}
          buttonText={t('homepage:trending:topics.1.buttonText')}
          buttonUrl="/design/#cloud-solutions"
          target="_self"
        />
        <Topic
          id={2}
          sectionName="home-topic"
          title={t('homepage:trending:topics.2.title')}
          description={t('homepage:trending:topics.2.description')}
          buttonText={t('homepage:trending:topics.2.buttonText')}
          buttonUrl="/change-communication/#secure-behaviour"
          target="_self"
        />
      </section>
    </div>
  );
}
