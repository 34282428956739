import React, {useState} from 'react';
import './LearningTechnology-Tabcontainer.scss';
import {Nav} from 'react-bootstrap';
import EcosystemAdvisory from 'assets/documents/learn-tech/LearnTech_EcosystemAdvisory.pdf';
import RoadmapDesign from 'assets/documents/learn-tech/LearnTech_RoadmapDesign.pdf';
import VendorSelection from 'assets/documents/learn-tech/LearnTech_VendorSelection.pdf';
import KnowledgeManagement from 'assets/documents/learn-tech/LearnTech_KnowledgeManagement.pdf';
import CornerStoneMan from 'assets/documents/learn-tech/LearnTech_CornerstoneOnDemand.pdf';
import ImplementationServices from 'assets/documents/learn-tech/LearnTech_ImplementationServices.pdf';

export default function LearningTechnologyTabcontainer() {
  const [tab, setTab] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  /* eslint-disable */
  const onClickHandler = activeTab => {
    if (!tab) {
      setTab(true);
    }
    setActiveTab(activeTab);
    // setActivePanel(activePanel);
    // if (tab) {
    //   setTab(false);
    // }
  };

  return (
    <div className="learning-technology">
      <div className="toggle-container">
        <button
          type="button"
          className={tab && activeTab === 1 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(1)}
        >
          {/* {tab && activeTab === 1 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 2 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(2)}
        >
          {/* {tab && activeTab === 2 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 3 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(3)}
        >
          {/* {tab && activeTab === 3 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
      </div>
      {tab && activeTab === 1 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header1">
                <h3>Learning Technology Roadmap Design</h3>
              </div>
              <div className="tabcontent-col header1">
                <h3>Learning Technology Ecosystem Advisory</h3>
              </div>
              <div className="tabcontent-col header1">
                <h3>Vendor Selection & Learning Process Consulting</h3>
              </div>
              <div className="tabcontent-col">
                <p>
                  Together, we co-create a north star vision for our clients’ learning technology
                  solutions. With our extensive experience and an eye on the newest developments
                  within the learning technology market, we design our client’s future learning
                  technology roadmap.  
                </p>
              </div>
              <div className="tabcontent-col">
                <p>
                  As the first step of a successful learning technology ecosystem transformation, we
                  analyze the current technology set up and identify key challenges and
                  opportunities for our clients.
                </p>
              </div>
              <div className="tabcontent-col">
                <p>
                  After an extensive analysis of the current technology set up, we recommend
                  suitable vendors to help our clients make the right decisions and streamline
                  learning processes.
                </p>
              </div>
              <div className="tabcontent-col">
                <a href={RoadmapDesign} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col">
                <a href={EcosystemAdvisory} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col">
                <a href={VendorSelection} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 2 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header2">
                <h3>Learning Technology Implementation Services</h3>
              </div>
              <div className="tabcontent-col header2">
                <h3>Knowledge Management Implementation Services</h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  By supporting with prototyping, testing, technical implementation, training and
                  change management we deliver a holistic end-to-end implementation for any type of
                  learning technology solution.  
                </p>
              </div>
              <div className="tabcontent-col">
                <p>
                  With our Knowledge Management Implementation Services, we bring together people,
                  teams and data into a more holistic, converged user experience, to enhance
                  individual and team productivity.
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={ImplementationServices} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col">
                <a href={KnowledgeManagement} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 3 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
          </Nav>
          <div className="tab-content">
            <section className="tabcontent-container">
              <div className="tabcontent-col header3">
                <h3>End-to-End Cornerstone OnDemand Partner</h3>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
              <div className="tabcontent-col">
                <p>
                  As certified and trusted Cornerstone OnDemand partner we support and drive a
                  sustainable implementation for Cornerstone&apos;s full Talent Suite. We offer
                  end-to-end consulting, implementation and maintenance services for all HR and
                  Learning Modules including learning content advisory. Due to our global
                  Cornerstone community, we assist clients anywhere and anytime.
                </p>
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <p />
              </div>
              <div className="tabcontent-col">
                <a href={CornerStoneMan} target="blank" rel="noopener noreferrer">
                  <button type="button" className="tabcontent-button">
                    More Information
                  </button>
                </a>
              </div>
              <div className="tabcontent-col" />
              <div className="tabcontent-col" />
            </section>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
