import React from 'react';
import './Team.scss';

export default function Team() {
  return (
    <section className="team-container">
      <div className="team team-1">
        <span className="section-name">
          The
          <br />
          Team
        </span>
        <h2>
          We are
          <span className="big-number">
            70<sup>+</sup>{' '}
          </span>
          Learning, Communication & Media Design Experts.
        </h2>
      </div>
      <div className="team team-2">
        <h2>We add value by </h2>
        <ul className="feature-list">
          <li>Developing compelling Digital Learning and Communication solutions.</li>
          <li>Fostering innovation in learning.</li>
          <li>Delivering locally and globally at any scale.</li>
        </ul>
      </div>
      <div className="team team-3">
        <div className="space-suit" />
      </div>
      <div className="team team-map small-device-hide" />
      <div className="team team-4">
        <h3>
          We are part of a<br /> global team of
          <span className="big-bumber big-number-team">650+</span> learning and communication
          experts.
        </h3>
        <h2 className="leadership-team">
          Leadership
          <br /> Team
        </h2>
      </div>
    </section>
  );
}
