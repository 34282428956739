import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {HashLink} from 'react-router-hash-link';
import './Topnav.scss';

import OfferingBlue from 'assets/images/offering-blue.png';
import OfferingGreene from 'assets/images/offering-green.png';
import OfferingOrange from 'assets/images/offering-orange.png';
import OfferingYellow from 'assets/images/offering-yellow.png';
import TheTeam from 'assets/images/team-space-nav.png';
import HomeIcon from 'assets/images/menu-home-icon.svg';
import ContactIcon from 'assets/images/menu-contact-icon.svg';
// import LangIcon from 'assets/images/menu-lang-de-icon.svg';
import {withTranslation} from 'react-i18next';

import {useHistory} from 'react-router-dom';

function Topnav() {
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();

  // const {language} = i18n;

  const toggleMenu = () => {
    setIsChecked(!isChecked);
  };

  // const changeLanguageNow = () => {
  //   toggleMenu();

  //   if (language === 'en') {
  //     i18n.changeLanguage('de');
  //   } else {
  //     i18n.changeLanguage('en');
  //   }
  // };

  const navigateTo = link => {
    toggleMenu();
    window.scrollTo(0, 0);
    history.push(link);
  };

  return (
    <nav className="topnav-container" role="navigation">
      <div className="burger-menu-button">
        <input type="checkbox" checked={isChecked} onChange={toggleMenu} />
        <span />
        <span />
        <span />
        <div id="menu-toggle">
          <div className="topnav-header">
            <p className="topnav-title">Our Offering</p>
          </div>
          <div className="topnav-grid">
            <div className="nav nav-1">
              <div
                className="menu-link menu-link-blue"
                role="button"
                tabIndex="0"
                onClick={() => navigateTo('/design')}
                onKeyPress={() => navigateTo('/design')}
              >
                <div className="img-container">
                  <img src={OfferingBlue} alt="Learning Design and Delivery" />
                </div>
                <p>
                  Learning <br /> Design & Development
                </p>
              </div>
            </div>
            <div className="nav nav-2">
              <div
                className="menu-link menu-link-green"
                role="button"
                tabIndex="0"
                onClick={() => navigateTo('/tech')}
                onKeyPress={() => navigateTo('/tech')}
              >
                <div className="img-container">
                  <img src={OfferingGreene} alt="Learning Technology" />
                </div>
                <p>
                  Learning <br />
                  Technology
                </p>
              </div>
            </div>
            <div className="nav nav-3">
              <div
                className="menu-link menu-link-orange"
                role="button"
                tabIndex="0"
                onClick={() => navigateTo('/change-communication')}
                onKeyPress={() => navigateTo('/change-communication')}
              >
                <div className="img-container">
                  <img src={OfferingOrange} alt="Change Communication" />
                </div>
                <p>
                  Change <br />
                  Communication
                </p>
              </div>
            </div>
            <div className="nav nav-4">
              <div
                className="menu-link menu-link-yellow"
                role="button"
                tabIndex="0"
                onClick={() => navigateTo('/multimedia-design')}
                onKeyPress={() => navigateTo('/multimedia-design')}
              >
                <div className="img-container">
                  <img src={OfferingYellow} alt="Multimedia Design" />
                </div>
                <p>
                  Multimedia
                  <br /> Design
                </p>
              </div>
            </div>
            <div className="nav nav-5">
              <HashLink to="/#the-team">
                <div
                  className="menu-link menu-link-purple"
                  role="button"
                  tabIndex="0"
                  onClick={() => toggleMenu()}
                  onKeyPress={() => toggleMenu()}
                >
                  <div className="img-container">
                    <img src={TheTeam} alt="The Team" />
                  </div>
                  <p>
                    The <br />
                    Team
                  </p>
                </div>
              </HashLink>
            </div>
            <div className="nav nav-6">
              <div
                role="button"
                tabIndex={0}
                onClick={() => navigateTo('/')}
                onKeyPress={() => navigateTo('/')}
              >
                <img src={HomeIcon} alt="Home" />
              </div>
              <a href="mailto:dirk.schwendt@accenture.com;gunnar.steinicke@accenture.com;tina.manderscheid@accenture.com;sylvia.berge@accenture.com?subject=Contact our Global Learning and Communication Experts">
                <img src={ContactIcon} alt="Home" />
              </a>
              {/* <button type="button" className="langSwitch" onClick={changeLanguageNow}>
                {language === 'en' ? 'DE' : 'EN'}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

// Topnav.propTypes = {
//   i18n: PropTypes.shape({
//     changeLanguage: PropTypes.func,
//     language: PropTypes.string
//   })
// };

// Topnav.defaultProps = {
//   i18n: ''
// };

export default withTranslation()(Topnav);
