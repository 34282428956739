import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import HeroBanner from 'components/shared/HeroBanner/HeroBanner';
import './Hero.scss';

export default function Hero() {
  const {t} = useTranslation();

  const hero = 'homepage:hero';
  const taglineTop = t(`${hero}:tagline-top`);
  const title = t(`${hero}:title`);
  const taglineBottom = t(`${hero}:tagline-bottom`);

  const description = (
    <ul className="feature-list">
      {[0, 1, 2].map(list => (
        <li key={list}>
          <Trans i18nKey={`${hero}:feature-list.${list}`}>
            {t(`${hero}:feature-list.${list}`)}
          </Trans>
        </li>
      ))}
    </ul>
  );

  const rightContentSlot = (
    <div className="small-device-hide">
      <div className="image image-0" />
      <div className="image image-1" />
      <div className="image image-2" />
      <div className="image image-3" />
      <div className="image image-4" />
      <div className="image image-5" />
    </div>
  );
  return (
    <HeroBanner
      page="home"
      taglineTop={taglineTop}
      description={description}
      title={title}
      taglineBottom={taglineBottom}
      rightContentSlot={rightContentSlot}
    />
  );
}
