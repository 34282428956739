import React from 'react';
import {Helmet} from 'react-helmet';
import Hero from './Hero';
import Topics from './Topics';
import Offering from './Offering';
import Team from './Team';
import TeamMembers from './TeamMembers';
import Podcast from './Podcast';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>GLACE | Accenture | Home</title>
      </Helmet>
      <Hero />
      <Topics />
      <Offering />
      <Team />
      <TeamMembers />
      <Podcast />
    </>
  );
}
