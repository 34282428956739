import React from 'react';
import './ChangeCom-Challenges.scss';
import Challenge from 'components/shared/Challenge/Challenge';
import PeopleIcon from 'assets/icons/peopleIcon.png';
import HandsIcon from 'assets/icons/handsIcon.png';
import StopIcon from 'assets/icons/stopIcon.png';

export default function ChangeComChallenges() {
  return (
    <div className="challenges below-hero">
      <h4>CHANGE COMMUNICATION</h4>
      <h2>Challenges</h2>
      <p className="challenge-introduction">
        Whether you are going through a major organizational change or an organization-wide system
        implementation: communicating the right messages to your stakeholders is essential to the
        success of your change efforts. We support both small and large-scale transformations with
        customized communication strategy, multimedia content creation and stakeholder engagement.
      </p>
      <section className="challenges-topic-container">
        <Challenge
          id={2}
          sectionName="challenges-topic"
          title="Provide custom-made communication formats for large-scale transformation programs"
          description="Major transformation projects usually cause an increased demand for employee engagement, customer focus, people growth and development, though leadership, and behavioral and cultural change."
          challengesIcon={PeopleIcon}
        />
        <Challenge
          id={3}
          sectionName="challenges-topic"
          title="Creating a vision for the change"
          description="Successful change projects require a clear vision of the future, knowledge of challenges and opportunities and an idea of actions that must be taken to achieve the desired future state."
          challengesIcon={HandsIcon}
        />
        <Challenge
          id={4}
          challengeId="secure-behaviour"
          sectionName="challenges-topic"
          title="Train users on secure behavior"
          description="How do we raise employee awareness for the importance of cyber security and empower them to protect sensitive data to lower their organization’s attack surface?"
          challengesIcon={StopIcon}
        />
      </section>
    </div>
  );
}
