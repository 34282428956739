import React from 'react';
import Proptypes from 'prop-types';
import './TeamMember.scss';

const TeamMember = ({id, firstname, lastname, role, role2, contact, profilePicture, gender}) => {
  return (
    <div className={`team-members team-members-${gender} team-member-${id}`}>
      <img src={profilePicture} className="profile-picture" alt={firstname + lastname} />
      <p className="firstname">{firstname}</p>
      <p className="lastname">{lastname}</p>
      <p className="role">{role}</p>
      <p className="role2">{role2}</p>
      <p className="contact">
        <a href={`mailto:${contact}`}>{contact}</a>
      </p>
    </div>
  );
};

TeamMember.propTypes = {
  id: Proptypes.number,
  firstname: Proptypes.string,
  lastname: Proptypes.string,
  role: Proptypes.string,
  role2: Proptypes.string,
  contact: Proptypes.string,
  profilePicture: Proptypes.string,
  gender: Proptypes.string
};

TeamMember.defaultProps = {
  id: 9999,
  firstname: 'John',
  lastname: 'Doe',
  role: 'Developer',
  role2: 'Lead',
  contact: 'dev@accenture.com',
  profilePicture: '',
  gender: 'male'
};

export default TeamMember;
