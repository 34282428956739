import React, {useState, useEffect} from 'react';
import './LearningDesign-Tabcontainer.scss';
import TabContainer from 'components/shared/TabContainer/TabContainer';
import DropDownButton from 'components/shared/TabContainer/DropDownButton';
import {Nav} from 'react-bootstrap';
import LearnTechImg from 'assets/images/learning-design/learn-tech-img.png';
import LaptopIconWhite from 'assets/images/learning-design/laptopIcon-white.png';
import VideoIcon from 'assets/images/learning-design/videoIcon.png';
import TopIcon from 'assets/images/learning-design/topIcon.png';
import challengesS4hana from 'assets/images/learning-design/challenges-s4hana.png';
import challengesCloudTraining from 'assets/images/learning-design/challenges-cloud-training.png';
import challengesEpssOffering from 'assets/images/learning-design/challenges-epss-offering.png';
import WebBasedTraining from 'assets/images/learning-design/web-based-trainings.png';
import VirtualClassTraining from 'assets/images/learning-design/virtual-classroom.png';
import VideoLearning from 'assets/images/learning-design/video-learning.png';
import Infographics from 'assets/images/learning-design/Infographic2.png';
import DigitalLearning from 'assets/images/learning-design/digital-learning-media.png';
import S4OfferingDeck from 'assets/documents/learn-design/LDD_S4_Offering_Deck.pdf';
import DeliveryModel from 'assets/documents/learn-design/LDD_Distributed_Delivery.pdf';
import SAPEnableNow from 'assets/documents/learn-design/LDD_SAP_Enable.pdf';
import EPSSPoV from 'assets/documents/learn-design/LDD_PSS_PoV.pdf';
import ValueChain from 'assets/documents/learn-design/LDD_ValueChain.pdf';
import ViewFormats from 'assets/documents/learn-design/LDD_Learning_Formats.pdf';
import ViewIntroduction from 'assets/documents/learn-design/LDD_Immersive_Learning.pdf';
import Top5 from 'assets/documents/learn-design/LDD_Top5Energizer.pdf';

export default function LearningDesignTabcontainer() {
  const [tab, setTab] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  /* eslint-disable */
  const onClickHandler = activeTab => {
    if (!tab) {
      setTab(true);
    }
    setActiveTab(activeTab);
  };

  useEffect(() => {
    if (window.location.hash === '#customized-learning') {
      console.log(window.location.hash);
      setTab(true);
      setActiveTab(3);
    }
    if (window.location.hash === '#cloud-solutions') {
      console.log(window.location.hash);
      setTab(true);
      setActiveTab(2);
    }
  }, [window.location.hash]);

  return (
    <div className="learning-design">
      <div className="toggle-container">
        <div className="empty-div" />
        <button
          type="button"
          className={tab && activeTab === 2 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(2)}
        >
          {/* {tab && activeTab === 2 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
        <button
          type="button"
          className={tab && activeTab === 3 ? 'open-class' : 'closed-class'}
          onClick={() => onClickHandler(3)}
        >
          {/* {tab && activeTab === 3 ? 'Close' : 'See how we can help'} */}
          See how we can help
        </button>
      </div>
      <div className="empty-div" />
      {tab && activeTab === 2 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
          </Nav>
          <div className="tab-content">
            <TabContainer
              id={1}
              colHeading="Supporting S4/Hana Employee Journey"
              colDescription="While employees cannot each be addressed individually, they can be grouped and enabled based on their motives, fears and motivations. Not only is our offering flexible in the selection of suitable measures and formats, but so is our delivery model with the goal of maximum (cost-)efficiency."
              btnTabText="More information"
            >
              <div className="tab-img-container">
                <img src={challengesS4hana} alt="several computer screens" />
              </div>
              <DropDownButton
                linkText1="S/4 Offering Deck"
                linkText2="Distributed Delivery Model"
                link1={S4OfferingDeck}
                link2={DeliveryModel}
              />
            </TabContainer>
            <TabContainer
              id={1}
              colHeading="Supporting whole value chain of the Cloud Training Project"
              colDescription="We support the training part of your cloud project end-to-end from Training- Needs-Analysis and training concept to hands on realization of training material."
              btnTabText="View Activity Flow"
            >
              <div className="tab-img-container">
                <img src={challengesCloudTraining} alt="several computer screens" />
              </div>
              <a href={ValueChain} target="_blank" rel="noopener noreferrer">
                <button type="button" className="tabcontent-button">
                  View Value Chain
                </button>
              </a>
            </TabContainer>
            <TabContainer
              id={1}
              colHeading="Develop Electronic Peformance Support Solutions (EPSS)"
              colDescription="Electronic Performance Support means getting the information on how to handle a system in the moment of need when working in the live system. EPSS will in the medium-term significantly reduce the need for training in advance."
              btnTabText="View Point of View"
            >
              <div className="tab-img-container">
                <img src={challengesEpssOffering} alt="several computer screens" />
              </div>
              <DropDownButton
                btnTitle="View Point of View"
                linkText1="SAP Enable Now"
                linkText2="EPSS PoV"
                link1={SAPEnableNow}
                link2={EPSSPoV}
              />
            </TabContainer>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab && activeTab === 3 ? (
        <div className="accordion-container">
          <Nav className="mr-auto">
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link className="hide-tab" href="#link">
              How we can help
            </Nav.Link>
            <Nav.Link href="#link">How we can help</Nav.Link>
          </Nav>
          <div className="tab-content">
            <TabContainer
              id={1}
              colHeading="Producing all kinds of learning formats"
              colDescription="Learning comes in many different formats. We are able to produce the whole range of training formats from standard classroom training and Web Based Training up Video Learning and many other formats."
              btnTabText="View Format overview"
            >
              <div className="tab2-img-container">
                <p>Overview of Learning formats</p>
                <div className="format-container">
                  <div>
                    <img src={WebBasedTraining} alt="white ellipse" />
                    <p>Web Based</p>
                    <p>Trainings</p>
                  </div>
                  <div>
                    <img src={VideoLearning} alt="white ellipse" />
                    <p>
                      Video <br />
                      Learning
                    </p>
                  </div>
                  <div>
                    <img src={VirtualClassTraining} alt="white ellipse" />
                    <p>Virtual Classroom Training</p>
                  </div>
                  <div>
                    <img src={DigitalLearning} alt="white ellipse" />
                    <p>Digital Learning Support Media</p>
                  </div>
                  <div>
                    <img src={Infographics} alt="white ellipse" />
                    <p>Infographics</p>
                  </div>
                </div>
              </div>
              <a href={ViewFormats} target="_blank" rel="noopener noreferrer">
                <button type="button" className="tabcontent-button">
                  View Formats
                </button>
              </a>
            </TabContainer>
            <TabContainer
              id={1}
              colHeading="Leverage Immersive Learning Technologies"
              colDescription="Using AR/VR for learning purposes becomes more and more important. We offer you end-to-end support creating your immersive Learning solution."
              btnTabText="View Format overview"
            >
              <div className="tab2-img-container">
                <img className="learn-tech-img" src={LearnTechImg} alt="Learn Technology People" />
              </div>
              <a href={ViewIntroduction} target="_blank" rel="noopener noreferrer">
                <button type="button" className="tabcontent-button">
                  View Introduction
                </button>
              </a>
            </TabContainer>
            <TabContainer
              id={1}
              colHeading="Virtualize Classroom Trainings"
              colDescription="The need for social distancing often creates the need to transform given classroom trainings into virtual training formats quickly. We have some help at hand for that."
              btnTabText="View Point of View"
              btnIdName="hide-button"
            >
              {/* <div className="virtual-training-highlight">
                <div>
                  <img src={LaptopIcon} alt="Videobutton Icon" />
                </div>
                <a href={VirtualizeNow} target="_blank" rel="noopener noreferrer">
                  <div className="description-icon">
                    <p>Virtualize your Classroom Training - Now</p>
                  </div>
                </a>
              </div> */}
              <div className="virtual-training-container">
                <div>
                  <img src={LaptopIconWhite} alt="Laptop Icon" />
                </div>
                <a href={Top5} target="_blank" rel="noopener noreferrer">
                  <div className="description-icon">
                    <p>Virtualize your Classroom Training - Now</p>
                    <p>
                      <span>Document</span>
                    </p>
                  </div>
                </a>
                <div>
                  <img src={VideoIcon} alt="Videobutton Icon" />
                </div>
                <div className="description-icon">
                  <a
                    href="https://my-engagement-common-asset.s3-eu-west-1.amazonaws.com/videos%23/Microsite_LD%26D_How+we+help_Recorded+Session_Development+of+virtual+trainings.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Development of virtual trainings</p>
                    <p>
                      <span>Recording: 6:49 min.</span>
                    </p>
                  </a>
                </div>
                <div>
                  <img src={VideoIcon} alt="Videobutton Icon" />
                </div>
                <div className="description-icon">
                  <a
                    href="https://my-engagement-common-asset.s3-eu-west-1.amazonaws.com/videos%23/Microsite_LD%26D_How+we+help_Recorded+Sessions_Example+for+a+Transformation.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Example for a transformation</p>
                    <p>
                      <span>Recording: 5:42 min.</span>
                    </p>
                  </a>
                </div>
                <div>
                  <img src={VideoIcon} alt="Videobutton Icon" />
                </div>
                <div className="description-icon">
                  <a
                    href="https://my-engagement-common-asset.s3-eu-west-1.amazonaws.com/videos%23/Microsite_LD%26D_How+we+help_Recorded+Session_Tipps+and+Tricks+for+conduction+of+virtual+trainings_ENGLISH.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Tipps & Tricks – conduct virtual trainings</p>
                    <p>
                      <span>Recording: 5:00 min.</span>
                    </p>
                  </a>
                </div>
                <div>
                  <img src={TopIcon} alt="Top Icon" />
                </div>
                <a href={Top5} target="_blank" rel="noopener noreferrer">
                  <div className="description-icon">
                    <p>Top 5 Energizer</p>
                    <p>
                      <span>Infographic</span>
                    </p>
                  </div>
                </a>
              </div>
            </TabContainer>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
