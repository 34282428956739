import React from 'react';
import Proptypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

const Layout = ({children}) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
Layout.propTypes = {
  children: Proptypes.node
};

Layout.defaultProps = {
  children: ''
};

export default Layout;
