import React from 'react';
import './TabContainer.scss';
import Proptypes from 'prop-types';

const TabContainer = ({id, colHeading, colDescription, children}) => {
  return (
    <div>
      <section className="tabcontent-container">
        <div className={`tabcontent-col header-${id}`}>
          <h3>{colHeading}</h3>
        </div>
        <div className="tabcontent-col description">
          <p>{colDescription}</p>
        </div>
        <div className="tabcontent-col col-content">{children}</div>
        <div className="tabcontent-button">
          {/* <button id={btnIdName} type="button" className="tabcontent-button">
            {btnTabText}
          </button> */}
          {/* <DropdownButton id="dropdown-basic-button" title="More information">
                <Dropdown.Item href="#/action-1">S/4 Offering</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Distributed Delivery Mode</Dropdown.Item>
                <Dropdown.Item href="#/action-3">SAP PMC</Dropdown.Item>
          </DropdownButton> */}
        </div>
      </section>
    </div>
  );
};

TabContainer.propTypes = {
  id: Proptypes.number,
  colHeading: Proptypes.string,
  colDescription: Proptypes.string,
  children: Proptypes.node
};

TabContainer.defaultProps = {
  id: 1,
  colHeading: 'Lorem, ipsum dolor sit amet consectetur',
  colDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  children: null
};

export default TabContainer;
