import React from 'react';
import Topnav from './ui/Topnav/Topnav';
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import {NavLink} from 'react-router-dom';

const Header = () => {
  return (
    <div>
      <Topnav />
    </div>
  );
};

export default Header;
