import React from 'react';
import './DropDownButton.scss';
import Proptypes from 'prop-types';
import {AiFillCaretDown} from 'react-icons/ai';

const DropDownButton = ({btnTitle, link1, link2, linkText1, linkText2}) => {
  return (
    <div>
      <div className="dropdown">
        <button type="button" className="dropbtn">
          {btnTitle}
          <AiFillCaretDown />
        </button>
        <div className="dropdown-content">
          <a href={link1} target="_blank" rel="noopener noreferrer">
            {linkText1}
          </a>
          <a href={link2} target="_blank" rel="noopener noreferrer">
            {linkText2}
          </a>
        </div>
      </div>
    </div>
  );
};

DropDownButton.propTypes = {
  btnTitle: Proptypes.string,
  link1: Proptypes.string,
  link2: Proptypes.string,
  linkText1: Proptypes.string,
  linkText2: Proptypes.string
};

DropDownButton.defaultProps = {
  btnTitle: 'More Information',
  link1: 'testLink1',
  link2: 'testLink2',
  linkText1: 'testLink1',
  linkText2: 'testLink2'
};

export default DropDownButton;
