import React from 'react';
import Button from 'components/ui/Button/Button';
import {HashLink} from 'react-router-hash-link';
import Proptypes from 'prop-types';
import './Topic.scss';

const Topic = ({id, title, hide, description, buttonText, sectionName, buttonUrl}) => {
  return (
    <div className={`topic ${sectionName}-${id}`}>
      <div className="header-description">
        <h3>
          {title.split('\n').map(item => {
            return (
              <span key={item}>
                {item}
                <br />
              </span>
            );
          })}
        </h3>
        <p className="topic-description">{description}</p>
      </div>
      <div className={hide}>
        {/* <a href={buttonUrl} target={target} rel="noopener noreferrer"> */}
        <HashLink to={buttonUrl}>
          <Button>{buttonText}</Button>
        </HashLink>
        {/* </a> */}
      </div>
    </div>
  );
};

Topic.propTypes = {
  id: Proptypes.number,
  title: Proptypes.string,
  hide: Proptypes.string,
  sectionName: Proptypes.string,
  description: Proptypes.string,
  buttonText: Proptypes.string,
  buttonUrl: Proptypes.string
};

Topic.defaultProps = {
  id: 99999,
  title: 'Title',
  hide: 'show',
  sectionName: 'home-topic',
  description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio libero iusto',
  buttonText: 'See full showcase',
  buttonUrl: '#'
};
export default Topic;
