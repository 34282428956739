import React from 'react';
import ChangeComHero from './components/ChangeCom-Hero';
import ChangeComChallenges from './components/ChangeCom-Challenges';
import ChangeComTabcontainer from './components/ChangeCom-Tabcontainer';
import ChangeComShowcase from './components/ChangeCom-Showcase';
import ChangeComFooterTopic from './components/ChangeCom-Footer-Topic';

const ChangeComPage = () => {
  return (
    <>
      <ChangeComHero />
      <ChangeComChallenges />
      <ChangeComTabcontainer />
      <ChangeComShowcase />
      <ChangeComFooterTopic />
    </>
  );
};

export default ChangeComPage;
