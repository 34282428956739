import React from 'react';
import './Challenge.scss';
import Proptypes from 'prop-types';

const Challenge = ({id, challengeId, title, description, sectionName, challengesIcon}) => {
  return (
    <div className={`challenge ${sectionName}-${id}`} id={challengeId}>
      <img className="challenge-icon" src={challengesIcon} alt="Challenge Icon" />
      <h3>
        {title.split('\n').map(item => {
          return (
            <span key={item}>
              {item}
              <br />
            </span>
          );
        })}
      </h3>
      <p className="challenge-description">{description}</p>
    </div>
  );
};

Challenge.propTypes = {
  id: Proptypes.number,
  challengeId: Proptypes.string,
  title: Proptypes.string,
  sectionName: Proptypes.string,
  description: Proptypes.string,
  challengesIcon: Proptypes.string
};

Challenge.defaultProps = {
  id: 1,
  challengeId: 'challengeId',
  title: 'Lorem, ipsum dolor sit amet consectetur',
  sectionName: 'challenges-topic',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  challengesIcon: 'icon'
};
export default Challenge;
