import React from 'react';
import FooterTopic from 'components/shared/Topic/FooterTopic';
import FooterTopicCarousel from 'components/shared/FooterTopicCarousel/FooterTopicCarousel';
import {useTranslation} from 'react-i18next';
import ShowcaseS4 from 'assets/documents/learn-design/Showcase_S4Implementation.pdf';
import ShowcaseCommerz from 'assets/documents/learn-design/Showcase_Commerzbank.pdf';
import ShowcaseDigital from 'assets/documents/learn-design/Showcase_DLW.pdf';
import './LearningDesign-Footer-Topic.scss';

const LearningDesignFooterTopic = () => {
  const {t} = useTranslation();

  const page = 'learning-design';
  const section = 'footer-topic';

  const sectionName = `${page}-${section}`;
  const tpath = `${page}:${section}`;

  return (
    <section className={`${sectionName}-container`}>
      <FooterTopicCarousel page={page}>
        <FooterTopic
          id={0}
          sectionName={sectionName}
          title={t(`${tpath}.0.title`)}
          description={t(`${tpath}.0.description`)}
          buttonUrl={ShowcaseS4}
        />
        <FooterTopic
          id={1}
          sectionName={sectionName}
          title={t(`${tpath}.1.title`)}
          description={t(`${tpath}.1.description`)}
          buttonUrl={ShowcaseCommerz}
        />
        <FooterTopic
          id={2}
          sectionName={sectionName}
          title={t(`${tpath}.2.title`)}
          description={t(`${tpath}.2.description`)}
          buttonUrl={ShowcaseDigital}
        />
      </FooterTopicCarousel>
    </section>
  );
};

export default LearningDesignFooterTopic;
